<template>
  <my-keep-view></my-keep-view>
</template>

<script>
  import MyKeepView from "../keepView";
export default {
components: {
  MyKeepView
}
}
</script>

<style>

</style>
